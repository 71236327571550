//
// Component: Main Header
//
.main-header {
    //   border-bottom: $main-header-bottom-border;
    z-index: $zindex-main-header;
    //   background: radial-gradient(rgba($primary, .5),rgba($warning,.3), transparent);
    //   background-color: #BCC0C5;
    .nav-link {
        height: $nav-link-height;
        position: relative;
    }
    .text-sm &,
    &.text-sm {
        .nav-link {
            height: $nav-link-sm-height;
            padding: $nav-link-sm-padding-y $nav-link-padding-x;
            >.fa,
            >.fas,
            >.far,
            >.fab,
            >.fal,
            >.fad,
            >.svg-inline--fa,
            >.ion {
                font-size: $font-size-sm;
            }
        }
    }
    .navbar-nav {
        .nav-item {
            margin: 0;
        }
        &[class*="-right"] {
            .dropdown-menu {
                left: auto;
                margin-top: -3px;
                right: 0;
                @media (max-width: breakpoint-max(xs)) {
                    left: 0;
                    right: auto;
                }
            }
        }
    }
    &.dropdown-legacy .dropdown-menu {
        top: $nav-link-height + $navbar-padding-y;
        margin-top: 0;
    }
}

// Add this class to images within a nav-link
.navbar-img {
    height: $main-header-height / 2;
    width: auto;
}

// Navbar badge
.navbar-badge {
    font-size: .6rem;
    font-weight: 300;
    padding: 2px 4px;
    position: absolute;
    right: 5px;
    top: 9px;
}

.btn-navbar {
    background-color: transparent;
    border-left-width: 0;
}

.form-control-navbar {
    border-right-width: 0;
    +.input-group-append {
        margin-left: 0;
    }
}

.form-control-navbar,
.btn-navbar {
    transition: none;
}

.navbar-dark {
    .form-control-navbar,
    .btn-navbar {
        background-color: $main-header-dark-form-control-bg;
        border-color: $main-header-dark-form-control-border-color;
    }
    .form-control-navbar {
        &::placeholder {
            color: $main-header-dark-placeholder-color;
        }
        +.input-group-append>.btn-navbar {
            color: $main-header-dark-placeholder-color;
        }
        &:focus {
            &,
            +.input-group-append .btn-navbar {
                background-color: $main-header-dark-form-control-focused-bg;
                border-color: $main-header-dark-form-control-focused-border-color !important;
                color: $main-header-dark-form-control-focused-color;
            }
        }
    }
}

.navbar-light {
    .form-control-navbar,
    .btn-navbar {
        background-color: $main-header-light-form-control-bg;
        border-color: $main-header-light-form-control-border-color;
    }
    .form-control-navbar {
        &::placeholder {
            color: $main-header-light-placeholder-color;
        }
        +.input-group-append>.btn-navbar {
            color: $main-header-light-placeholder-color;
        }
        &:focus {
            &,
            +.input-group-append .btn-navbar {
                background-color: $main-header-light-form-control-focused-bg;
                border-color: $main-header-light-form-control-focused-border-color !important;
                color: $main-header-light-form-control-focused-color;
            }
        }
    }
    .navbar-search-block {
        .form-control-navbar {
            &:focus {
                &,
                +.input-group-append .btn-navbar {
                    color: $main-header-light-placeholder-color;
                }
            }
        }
    }
}

// Navbar Search
.navbar-search-block {
    position: absolute;
    padding: 0 $nav-link-padding-x;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: none;
    justify-content: center;
    flex-direction: column;
    background-color: initial;
    &.navbar-search-open {
        display: flex;
    }
    .input-group {
        width: 100%;
    }
}